import React, { Component } from "react";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import separator from "./assets/images/page_separator.svg";
import * as utils from "./assets/js/uitls.js";
import "./assets/css/App.css";

const images = utils.importAll(
  require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollOps: window.pageYOffset,
      visible: true
    }

    this.homeRef = null;
    this.aboutRef = null;
    this.contactRef = null;

    this.setHomeRef = element => {
      this.homeRef = element;
    };

    this.scrollToHome = () => {
      if (this.homeRef) {
        if (!this.useSmoothScroll()) {
          this.homeRef.scrollIntoView();
        } else {
          utils.SmoothVerticalScrolling(this.homeRef, 275, "top");
        }
      }
    };

    this.setAboutRef = element => {
      this.aboutRef = element;
    };

    this.scrollToAbout = () => {
      if (this.aboutRef) {
        if (!this.useSmoothScroll()) {
          this.aboutRef.scrollIntoView();
        } else {
          utils.SmoothVerticalScrolling(this.aboutRef, 275, "top");
        }
      }
    };

    this.setContactRef = element => {
      this.contactRef = element;
    };

    this.scrollToContact = () => {
      if (this.contactRef) {
        if (!this.useSmoothScroll()) {
          this.contactRef.scrollIntoView();
        } else {
          utils.SmoothVerticalScrolling(this.contactRef, 360, "top");
        }
      }
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollOps } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollOps > currentScrollPos;

    this.setState ({
      prevScrollOps: currentScrollPos,
      visible
    });
  }

  useSmoothScroll() {
    if (utils.isChrome || utils.isFirefox) {
      return false;
    } else if (utils.isSafari || utils.isIE || utils.isEdge) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="parent">
        <Navbar fuild collapseOnSelect fixedTop>
          <Navbar.Header>
            <Navbar.Brand>Infinite Heroes</Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem onClick={this.scrollToHome}>Home</NavItem>
              <NavItem onClick={this.scrollToAbout}>About</NavItem>
              <NavItem onClick={this.scrollToContact}>Contact Us</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="wrapper">
          <div className="home" ref={this.setHomeRef}>
            <div className="left">
              <h1>Local Comic Shop for all your nerdy needs!</h1>
              <p>
                All of your comics, trading cards, and other collectibles in one
                place!
              </p>
            </div>
            <div className="right">
              <img src={images["infinite_heroes.png"]} className="App-logo" alt="logo" />
            </div>
          </div>
          <div className="about" ref={this.setAboutRef}>
            <img src={separator} className="page-separator" alt="separator" />
            <h1>About Us</h1>
            <section className="aboutus">
              <div className="left">
                <img
                  src={images["image_14.jpg"]}
                  className="App-about"
                  alt="logo"
                />
              </div>
              <div className="right">
                <h2>Over 20 years of experience!</h2>
                <p>
                  Owner Paul Santos has been working in the comic book industry since college. An internship at Warner Bros. turned into a career as an Editor as DC Comics.
                  <br/><br/>Paul has since moved back to Connecticut from California to pursue his dream of owning his own comic book shop!
                </p>
              </div>
            </section>
            <section class="features">
              <article>
                <div class="image">
                  <img src={images["image_4.png"]} alt="" />
                </div>
                <h3 class="major">New Comics</h3>
                <p class="major">Hundreds of new comics every Wednesday!</p>
              </article>
              <article>
                <div class="image">
                  <img src={images["image_6.png"]} alt="" />
                </div>
                <h3 class="major">DC Comics</h3>
                <p class="major">
                  All the DC comics! Batman, Superman, Green Lantern!
                </p>
              </article>
              <article>
                <div class="image">
                  <img src={images["image_5.jpg"]} alt="" />
                </div>
                <h3 class="major">Marvel Comics</h3>
                <p class="major">From Ant-Man to Zemo we got 'em all!</p>
              </article>
              <article>
                <div class="image">
                  <img src={images["image_9.jpg"]} alt="" />
                </div>
                <h3 class="major">Trading Cards</h3>
                <p class="major">
                  Magic the Gathering, Pokemon, and many other trading cards
                  available!
                </p>
              </article>
              <article>
                <div class="image">
                  <img src={images["image_10.jpg"]} alt="" />
                </div>
                <h3 class="major">Wall Books</h3>
                <p class="major">
                  Huge selection of wall books and graphic novels.
                </p>
              </article>
              <article>
                <div class="image">
                  <img src={images["image_13.jpg"]} alt="" />
                </div>
                <h3 class="major">Collectibles</h3>
                <p class="major">
                  Many unique collectibles and statues available. Including Funko Pop vinyls
                </p>
              </article>
            </section>
          </div>
          <section id="footer" ref={this.setContactRef}>
            <img src={separator} className="page-separator" alt="separator" />
            <h1>Contact Us</h1>
            <div class="inner">
              <hr />
              <p>
                Get in touch with us anytime! Our hours are 11:00 am to 4:00 pm
                Tuesday, 11:00 am to 7:00 pm Wednesday to Saturday, 12:00 pm to 5:00 pm on Sunday and closed on Monday.
                <br /><br />
                Follow us on Facebook or Instagram to stay up to date on all our
                upcoming events and new comics that get released!
              </p>
              <ul class="contact">
                <li class="icon solid fa-home">
                  Infinite Heroes Comics
                  <br />
                  1098 Main St
                  <br />
                  Watertown, CT 06795
                </li>
                <li class="icon solid fa-phone">860-417-2559</li>
                <li class="icon solid fa-envelope">
                  <a href="mailto:paul@infiniteheroes.net">
                    paul@infiniteheroes.net
                  </a>
                </li>
                <li class="icon brands fa-facebook-f">
                  <a href="https://www.facebook.com/infiniteheroescomics/">
                    facebook.com/infiniteheroescomics
                  </a>
                </li>
                <li class="icon brands fa-instagram">
                  <a href="https://www.instagram.com/infiniteheroescomics/">
                    instagram.com/infiniteheroescomics
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <div className="copyright">
            <hr />
            <p>
              © Infinite Heroes 2019. All rights reserved. &nbsp;&nbsp; |
              &nbsp;&nbsp; Design: Tigersdontswim{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
