
/**
 * Brower Detection
 */

// Chrome 1 - 71
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]" 
export const isSafari = !!navigator.userAgent.match(/Version\/[d.]+.*Safari/);
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

/**
 * Smooth Scrolling
 */
export function SmoothVerticalScrolling(e, time, where) {
  var eTop = e.getBoundingClientRect().top;
  var eAmt = eTop / 100;
  var curTime = 0;
  while (curTime <= time) {
      window.setTimeout(SVS_B, curTime, eAmt, where);
      curTime += time / 100;
  }
}

function SVS_B(eAmt, where) {
  if (where === "center" || where === "")
      window.scrollBy(0, eAmt / 2);
  if (where === "top")
      window.scrollBy(0, eAmt);
}

/**
 * Import All
 */
export function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

